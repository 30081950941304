<template>
  <div id="mian">
    <div>
      <div class="content">
        <div class="desc">
          <h5 class="jsxx">订单信息</h5>
        </div>
        <ul class="listBox">
          <li>
            <span>结算流水号</span>
            <i class="hidden1">
              {{ detail.plfSettleOrderNo ? detail.plfSettleOrderNo : "" }}</i>
          </li>
          <li>
            <span>产品类型</span>
            <i class="hidden1">
            {{ productCode[detail.productCode] }}</i>
          </li>
          <li>
            <span>分账商户名称</span>
            <i class="hidden1">
              {{ detail.allocMchName ? detail.allocMchName : "" }}</i>
          </li>
          <li>
            <span>分账商户编号</span>
            <i class="hidden1">
              {{ detail.allocMchId ? detail.allocMchId : "" }}</i>
          </li> 
          <li>
            <span>所属商户名称</span>
            <i class="hidden1">
              {{ detail.mchName ? detail.mchName : "" }}</i>
          </li>
          <li>
            <span>所属商户编号</span>
            <i class="hidden1">
            {{ detail.mchId ? detail.mchId : "" }} </i>
          </li>  
          <li>
            <span>申请时间</span>
            <i class="hidden1">
              {{ detail.createTime ? detail.createTime : "" }}</i>
          </li>
          <li>
            <span>审核时间</span>
            <i class="hidden1">
            {{ detail.checkTime ? detail.checkTime : "" }}</i>
          </li>
          <li>
            <span>结算账户卡号</span>
            <i class="hidden1">
              {{ detail.accountNo ? detail.accountNo : "" }}</i>
          </li>
          <li>
            <span>结算账户名称</span>
            <i class="hidden1">
              {{ detail.accountName ? detail.accountName : "" }}</i>
          </li>
          <li>
            <span>结算金额</span>
            <i class="hidden1">
              {{ ( detail.settleAmount / 100) ? (detail.settleAmount / 100) : "" }}</i>
          </li>
          <li>
            <span>审核状态</span>
            <i class="hidden1" v-if=" detail.checkStatus == 'WAIT'">待审核</i>
            <i class="hidden1" v-if=" detail.checkStatus == 'REJECT'">已驳回</i>
            <i class="hidden1" v-if=" detail.checkStatus == 'PASS'">已审核</i>
          </li> 
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getManualDetail } from "@/api/trading/subaccount.js";
// import { getBank } from "@/utils/bank.js";
export default {
  data() {
    return {
      crumbs: "交易详情",
      detail: "",
      // bank: getBank(),
      productCode:{
					2:'分账方-手工结算(可结算余额结算)',
					3:'分账方-D0 手工结算(当日收款金额结算)'
			}
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const settleOrderId = this.$route.query.id
      getManualDetail(settleOrderId).then((res) => {
        console.log(this.$route.query.id);
        if (res) {
          this.detail = res.resultData.detail;
        }
      });
    },
    //菜单切换
    navClick(data) {
      this.crumbs = data;
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}
</style>
